import React, { useEffect, useState, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Wrapper } from "@googlemaps/react-wrapper";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import YearFilter from "../components/YearFilter";
import MapFilterBar from "../components/MapFilterBar";
import RefreshButton from "../components/RefreshButton";
import Sidebar from "../components/Sidebar";
import axios from "axios";
import { checkAuth } from "../utils/auth-utils";

const Mapa = () => {
  const [deliveries, setDeliveries] = useState([]);
  const [filteredDeliveries, setFilteredDeliveries] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedDelivery, setSelectedDelivery] = useState(null);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedUrgencies, setSelectedUrgencies] = useState([]);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [regionOptions, setRegionOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [mapCenter, setMapCenter] = useState({ lat: -14.235, lng: -51.9253 });
  const [driverName, setDriverName] = useState("");
  const [routeDate, setRouteDate] = useState("");
  const [routeNameError, setRouteNameError] = useState("");
  const [driverNameError, setDriverNameError] = useState("");
  const [routeDateError, setRouteDateError] = useState("");
  const [deliveryIdsError, setDeliveryIdsError] = useState("");

  const navigate = useNavigate();
  const mapRef = useRef(null);
  const markersRef = useRef([]);
  const clustererRef = useRef(null);

  const getCachedData = (year) => {
    const cachedData = localStorage.getItem(`deliveries_${year}`);
    return cachedData ? JSON.parse(cachedData) : null;
  };

  const setCachedData = (year, data) => {
    localStorage.setItem(`deliveries_${year}`, JSON.stringify(data));
  };

  const fetchDeliveries = useCallback(
    async (forceRefresh = false) => {
      if (!checkAuth(navigate)) return;

      setIsLoading(true);
      const token = localStorage.getItem("token");

      if (!forceRefresh) {
        const cachedData = getCachedData(selectedYear);
        if (cachedData) {
          setDeliveries(cachedData);
          const uniqueRegions = [
            ...new Set(cachedData.map((delivery) => delivery.region)),
          ];
          setRegionOptions(
            uniqueRegions.map((region) => ({ value: region, label: region }))
          );
          setIsLoading(false);
          return;
        }
      }

      try {
        const response = await axios.get(
          `https://api.logisticaaqq.com.br/deliveries/?year=${selectedYear}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          setDeliveries(response.data);
          setCachedData(selectedYear, response.data);
          const uniqueRegions = [
            ...new Set(response.data.map((delivery) => delivery.region)),
          ];
          setRegionOptions(
            uniqueRegions.map((region) => ({ value: region, label: region }))
          );
        } else {
          setErrorMessage(
            "Erro ao obter os dados. Por favor, tente novamente."
          );
        }
      } catch (error) {
        console.error("Erro ao buscar entregas:", error);
        setErrorMessage("Erro de rede. Por favor, tente novamente mais tarde.");
      } finally {
        setIsLoading(false);
      }
    },
    [selectedYear, navigate]
  );

  useEffect(() => {
    if (!checkAuth(navigate)) return;
    fetchDeliveries();
  }, [fetchDeliveries, navigate]);

  const getUrgencyLabel = useCallback((deliveryDate, deliveryStatus) => {
    if (deliveryStatus === "Entregue") return "Entregue";
    if (!deliveryDate) return "Data Inválida";

    const currentDate = new Date();
    const deliveryDateObj = new Date(deliveryDate);

    if (isNaN(deliveryDateObj.getTime())) return "Data Inválida";

    currentDate.setHours(0, 0, 0, 0);
    deliveryDateObj.setHours(0, 0, 0, 0);

    const dayDiff = Math.ceil(
      (deliveryDateObj - currentDate) / (1000 * 60 * 60 * 24)
    );

    if (dayDiff < 0) return "Atrasada";
    if (dayDiff <= 10) return "Alta";
    if (dayDiff <= 15) return "Média";
    if (dayDiff >= 20) return "Baixa";
    return "Nenhuma";
  }, []);

  const getUrgencyColor = useCallback(
    (deliveryDate, deliveryStatus) => {
      const urgency = getUrgencyLabel(deliveryDate, deliveryStatus);
      switch (urgency) {
        case "Atrasada":
          return "#A020F0";
        case "Alta":
          return "#FF0000";
        case "Média":
          return "#FFFF00";
        case "Baixa":
          return "#0000FF";
        default:
          return "#808080";
      }
    },
    [getUrgencyLabel]
  );

  useEffect(() => {
    let filtered = deliveries;

    if (searchTerm) {
      filtered = filtered.filter((delivery) =>
        Object.values(delivery).some((value) =>
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }

    if (selectedUrgencies.length > 0) {
      filtered = filtered.filter((delivery) =>
        selectedUrgencies.includes(
          getUrgencyLabel(delivery.data_entrega, delivery.status)
        )
      );
    }

    if (selectedRegions.length > 0) {
      filtered = filtered.filter((delivery) =>
        selectedRegions.includes(delivery.region)
      );
    }

    setFilteredDeliveries(filtered);
  }, [
    deliveries,
    searchTerm,
    selectedUrgencies,
    selectedRegions,
    getUrgencyLabel,
  ]);

  const handleMarkerClick = useCallback((delivery) => {
    setSelectedDelivery(delivery);
    setMapCenter({ lat: delivery.latitude, lng: delivery.longitude });
  }, []);

  const updateMap = useCallback(() => {
    if (!mapRef.current) return;

    markersRef.current.forEach((marker) => marker.setMap(null));
    markersRef.current = [];

    const bounds = new window.google.maps.LatLngBounds();

    filteredDeliveries.forEach((delivery) => {
      const position = { lat: delivery.latitude, lng: delivery.longitude };
      const marker = new window.google.maps.Marker({
        position,
        map: mapRef.current,
        title: delivery.escola,
        icon: {
          path: window.google.maps.SymbolPath.CIRCLE,
          fillColor: getUrgencyColor(delivery.data_entrega, delivery.status),
          fillOpacity: 1,
          strokeWeight: 0,
          scale: 10,
        },
      });

      marker.addListener("click", () => handleMarkerClick(delivery));
      markersRef.current.push(marker);
      bounds.extend(position);
    });

    if (clustererRef.current) {
      clustererRef.current.clearMarkers();
      clustererRef.current.addMarkers(markersRef.current);
    } else {
      clustererRef.current = new MarkerClusterer({
        map: mapRef.current,
        markers: markersRef.current,
      });
    }

    if (markersRef.current.length > 0) {
      mapRef.current.fitBounds(bounds);
    }
  }, [filteredDeliveries, getUrgencyColor, handleMarkerClick]);

  useEffect(() => {
    updateMap();
  }, [updateMap]);

  const onMapLoad = useCallback(
    (map) => {
      mapRef.current = map;
      updateMap();
    },
    [updateMap]
  );

  const onRefresh = () => {
    fetchDeliveries(true);
  };

  const handleAddRoute = async (selectedDeliveries, routeName) => {
    if (!checkAuth(navigate)) return;

    setErrorMessage("");
    setRouteNameError("");
    setDriverNameError("");
    setRouteDateError("");
    setDeliveryIdsError("");

    if (!routeName.trim()) {
      setRouteNameError("Please enter a route name.");
      return;
    }

    if (!driverName.trim()) {
      setDriverNameError("Please enter a driver name.");
      return;
    }

    if (!routeDate) {
      setRouteDateError("Please select a route date.");
      return;
    }

    const selectedDate = new Date(routeDate);
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    if (selectedDate < currentDate) {
      setRouteDateError("Route date cannot be in the past.");
      return;
    }

    if (selectedDeliveries.length === 0) {
      setDeliveryIdsError("Please select at least one delivery for the route.");
      return;
    }

    const token = localStorage.getItem("token");
    try {
      await axios.post(
        "https://api.logisticaaqq.com.br/routes/",
        {
          name: routeName,
          driver_name: driverName,
          route_date: routeDate,
          delivery_ids: selectedDeliveries.map((d) => d.id),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      alert("Route added successfully!");
      setDriverName("");
      setRouteDate("");
    } catch (error) {
      console.error("Error adding route:", error);
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        setErrorMessage(
          error.response.data.detail || "Failed to add route. Please try again."
        );
      } else if (error.request) {
        console.error("No response received:", error.request);
        setErrorMessage(
          "Failed to add route: No response from server. Please try again."
        );
      } else {
        console.error("Error setting up request:", error.message);
        setErrorMessage(`Failed to add route: ${error.message}`);
      }
    }
  };

  const renderMap = () => {
    return (
      <Wrapper
        apiKey="AIzaSyA3lSkTnPg-u2i6PEoc4DcLZCfIho7K0i4"
        libraries={["places"]}
      >
        <div
          style={{ height: "90vh", width: "100%" }}
          ref={(el) => {
            if (el && !mapRef.current) {
              const map = new window.google.maps.Map(el, {
                center: mapCenter,
                zoom: 4,
              });
              onMapLoad(map);
            }
          }}
        />
      </Wrapper>
    );
  };

  if (isLoading) return <div>Carregando...</div>;

  return (
    <div className="min-h-screen bg-gray-100 p-4">
      <div className="w-full max-w-7xl mx-auto">
        <div className="flex items-center justify-between mb-4">
          <YearFilter
            selectedYear={selectedYear}
            setSelectedYear={setSelectedYear}
          />
          <RefreshButton onRefresh={onRefresh} isLoading={isLoading} />
        </div>
        <MapFilterBar
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          selectedUrgencies={selectedUrgencies}
          setSelectedUrgencies={setSelectedUrgencies}
          selectedRegions={selectedRegions}
          setSelectedRegions={setSelectedRegions}
          regionOptions={regionOptions}
        />
        {errorMessage && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4">
            <strong className="font-bold">Erro: </strong>
            <span>{errorMessage}</span>
          </div>
        )}
        <div className="flex">
          <div className="w-3/4 rounded-lg overflow-hidden">{renderMap()}</div>
          <Sidebar
            deliveries={filteredDeliveries}
            selectedDelivery={selectedDelivery}
            onAddRoute={handleAddRoute}
            driverName={driverName}
            setDriverName={setDriverName}
            routeDate={routeDate}
            setRouteDate={setRouteDate}
            routeNameError={routeNameError}
            driverNameError={driverNameError}
            routeDateError={routeDateError}
            deliveryIdsError={deliveryIdsError}
            errorMessage={errorMessage}
          />
        </div>
      </div>
    </div>
  );
};

export default Mapa;
