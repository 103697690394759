import React from "react";

const DeliveryRow = ({ delivery, getUrgencyLabel, getStatusColor }) => {
  const urgencyLabel = getUrgencyLabel(delivery.data_entrega, delivery.status);
  const statusColor = getStatusColor(delivery.status);

  let urgencyClass = "";
  if (urgencyLabel === "Alta") {
    urgencyClass = "bg-red-500";
  } else if (urgencyLabel === "Média") {
    urgencyClass = "bg-yellow-500";
  } else if (urgencyLabel === "Baixa") {
    urgencyClass = "bg-blue-500";
  } else if (urgencyLabel === "Atrasada") {
    urgencyClass = "bg-purple-500";
  } else if (urgencyLabel === "Entregue") {
    urgencyClass = "bg-green-500";
  } else {
    urgencyClass = "bg-gray-500";
  }

  return (
    <tr className="border-b hover:bg-gray-100 transition-colors">
      <td className="py-3 px-5">{delivery.npedido}</td>
      <td className="py-3 px-5">{delivery.escola}</td>
      <td className="py-3 px-5">{delivery.cnpj}</td>
      <td className="py-3 px-5">{delivery.cep}</td>
      <td className="py-3 px-5">{delivery.data_entrega}</td>
      <td className="py-3 px-5">
        <span className={`px-2 py-1 rounded-full text-white ${statusColor}`}>
          {delivery.status}
        </span>
      </td>
      <td className="py-3 px-5">
        <span className={`px-2 py-1 rounded-full text-white ${urgencyClass}`}>
          {urgencyLabel}
        </span>
      </td>
      <td className="py-3 px-5">{delivery.obs}</td>
    </tr>
  );
};

export default DeliveryRow;
