import React, { useState, useRef, useEffect } from "react";

const DropdownMultiSelect = ({
  options,
  value,
  onChange,
  placeholder,
  label,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleToggle = () => setIsOpen(!isOpen);

  const handleChange = (optionValue) => {
    const newValue = value.includes(optionValue)
      ? value.filter((v) => v !== optionValue)
      : [...value, optionValue];
    onChange(newValue);
  };

  const handleSelectAll = () => {
    onChange(
      value.length === options.length
        ? []
        : options.map((option) => option.value)
    );
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <label className="block text-sm font-medium text-gray-700 mb-1">
        {label}
      </label>
      <div
        className="w-full p-2 border border-gray-300 rounded-lg bg-white shadow-sm cursor-pointer flex justify-between items-center"
        onClick={handleToggle}
      >
        <span className="truncate">
          {value.length > 0
            ? `${value.length} selecionado${value.length > 1 ? "s" : ""}`
            : placeholder}
        </span>
        <svg
          className="h-5 w-5 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </div>
      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-lg shadow-lg">
          <div className="p-2 border-b border-gray-300">
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={value.length === options.length}
                onChange={handleSelectAll}
                className="rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              />
              <span className="text-sm font-medium text-gray-700">
                Selecionar todos
              </span>
            </label>
          </div>
          <div className="max-h-60 overflow-y-auto">
            {options.map((option) => (
              <label
                key={option.value}
                className="flex items-center p-2 hover:bg-gray-100"
              >
                <input
                  type="checkbox"
                  checked={value.includes(option.value)}
                  onChange={() => handleChange(option.value)}
                  className="rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                />
                <span className="ml-2 text-sm text-gray-700">
                  {option.label}
                </span>
              </label>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const MapFilterBar = ({
  searchTerm,
  setSearchTerm,
  selectedUrgencies,
  setSelectedUrgencies,
  selectedRegions,
  setSelectedRegions,
  regionOptions,
}) => {
  const urgencies = [
    { value: "Alta", label: "Alta" },
    { value: "Média", label: "Média" },
    { value: "Baixa", label: "Baixa" },
    { value: "Atrasada", label: "Atrasada" },
  ];

  return (
    <div className="bg-white shadow-md rounded-lg p-4 mb-4">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div>
          <label
            htmlFor="search"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            Pesquisar
          </label>
          <input
            id="search"
            type="text"
            placeholder="Pesquisar..."
            className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-sm"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <DropdownMultiSelect
          options={urgencies}
          value={selectedUrgencies}
          onChange={setSelectedUrgencies}
          placeholder="Selecione as urgências"
          label="Urgências"
        />
        <DropdownMultiSelect
          options={regionOptions}
          value={selectedRegions}
          onChange={setSelectedRegions}
          placeholder="Selecione as regiões"
          label="Regiões"
        />
      </div>
    </div>
  );
};

export default MapFilterBar;
