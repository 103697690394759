import React, { useState, useEffect } from "react";
import axios from "axios";

const EditDeliveryForm = ({ delivery, onClose, onSave }) => {
  const [formData, setFormData] = useState({
    npedido: "",
    escola: "",
    cnpj: "",
    cep: "",
    data_entrega: "",
    obs: "",
    status: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState(""); // For success messages

  // Helper function to validate CNPJ
  const validateCNPJ = (cnpj) => {
    cnpj = cnpj.replace(/[^\d]+/g, ""); // Remove non-numeric characters

    if (cnpj.length !== 14) return false;

    if (/^(\d)\1+$/.test(cnpj)) return false;

    let length = cnpj.length - 2;
    let numbers = cnpj.substring(0, length);
    let digits = cnpj.substring(length);
    let sum = 0;
    let pos = length - 7;

    for (let i = length; i >= 1; i--) {
      sum += numbers.charAt(length - i) * pos--;
      if (pos < 2) pos = 9;
    }

    let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (result !== parseInt(digits.charAt(0))) return false;

    length += 1;
    numbers = cnpj.substring(0, length);
    sum = 0;
    pos = length - 7;

    for (let i = length; i >= 1; i--) {
      sum += numbers.charAt(length - i) * pos--;
      if (pos < 2) pos = 9;
    }

    result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (result !== parseInt(digits.charAt(1))) return false;

    return true;
  };

  // Helper function to validate CEP
  const validateCEP = (cep) => {
    const cepPattern = /^[0-9]{5}-?[0-9]{3}$/; // Valid format #####-###
    return cepPattern.test(cep);
  };

  // Populate form with existing delivery data
  useEffect(() => {
    if (delivery) {
      setFormData({
        npedido: delivery.npedido,
        escola: delivery.escola,
        cnpj: delivery.cnpj,
        cep: delivery.cep,
        data_entrega: delivery.data_entrega,
        obs: delivery.obs,
        status: delivery.status,
      });
    }
  }, [delivery]);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");

    // Validate CNPJ
    if (!validateCNPJ(formData.cnpj)) {
      setErrorMessage("CNPJ inválido");
      setSuccessMessage(""); // Clear success message if there's an error
      return;
    }

    // Validate CEP
    if (!validateCEP(formData.cep)) {
      setErrorMessage("CEP inválido");
      setSuccessMessage(""); // Clear success message if there's an error
      return;
    }

    try {
      await axios.patch(
        `https://api.logisticaaqq.com.br/deliveries/${delivery.id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setErrorMessage("");
      setSuccessMessage("Entrega atualizada com sucesso!"); // Display success message
      if (onSave) {
        onSave(); // Trigger the onSave callback to refresh the delivery list
      }
      onClose(); // Close the form after saving
    } catch (error) {
      console.error("Erro ao atualizar a entrega:", error);
      setErrorMessage("Erro ao atualizar a entrega.");
      setSuccessMessage(""); // Clear success message if there's an error
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg w-full max-w-md">
        <h2 className="text-xl font-bold mb-4">Editar Entrega</h2>
        {/* Display error message if present */}
        {errorMessage && (
          <div className="text-red-500 mb-4">{errorMessage}</div>
        )}
        {/* Display success message if present */}
        {successMessage && (
          <div className="text-green-500 mb-4">{successMessage}</div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label htmlFor="npedido">Número do Pedido</label>
              <input
                type="text"
                name="npedido"
                value={formData.npedido}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded-lg"
                required
              />
            </div>
            <div>
              <label htmlFor="escola">Escola</label>
              <input
                type="text"
                name="escola"
                value={formData.escola}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded-lg"
                required
              />
            </div>
            <div>
              <label htmlFor="cnpj">CNPJ</label>
              <input
                type="text"
                name="cnpj"
                value={formData.cnpj}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded-lg"
                required
              />
            </div>
            <div>
              <label htmlFor="cep">CEP</label>
              <input
                type="text"
                name="cep"
                value={formData.cep}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded-lg"
                required
              />
            </div>
            <div>
              <label htmlFor="data_entrega">Data de Entrega</label>
              <input
                type="date"
                name="data_entrega"
                value={formData.data_entrega}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded-lg"
                required
              />
            </div>
            <div>
              <label htmlFor="status">Status</label>
              <select
                name="status"
                value={formData.status}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded-lg"
              >
                <option value="Não Entregue">Não Entregue</option>
                <option value="Em Trânsito">Em Trânsito</option>
                <option value="Entregue">Entregue</option>
              </select>
            </div>
          </div>
          <div className="mt-4">
            <label htmlFor="obs">Observações</label>
            <textarea
              name="obs"
              value={formData.obs}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded-lg"
            />
          </div>
          <div className="mt-4 flex justify-end space-x-2">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-500 text-white rounded-lg"
            >
              Cancelar
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded-lg"
            >
              Salvar Alterações
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditDeliveryForm;
