import React from "react";
import DropdownMultiSelect from "./DropdownMultiSelect";

const FilterBar = ({
  searchTerm,
  setSearchTerm,
  selectedMonths,
  setSelectedMonths,
  selectedUrgencies,
  setSelectedUrgencies,
  selectedStatuses,
  setSelectedStatuses,
  showDelivered,
  setShowDelivered,
}) => {
  const months = [
    { value: "01", label: "Janeiro" },
    { value: "02", label: "Fevereiro" },
    { value: "03", label: "Março" },
    { value: "04", label: "Abril" },
    { value: "05", label: "Maio" },
    { value: "06", label: "Junho" },
    { value: "07", label: "Julho" },
    { value: "08", label: "Agosto" },
    { value: "09", label: "Setembro" },
    { value: "10", label: "Outubro" },
    { value: "11", label: "Novembro" },
    { value: "12", label: "Dezembro" },
  ];

  const urgencies = [
    { value: "Alta", label: "Alta" },
    { value: "Média", label: "Média" },
    { value: "Baixa", label: "Baixa" },
    { value: "Atrasada", label: "Atrasada" },
  ];

  const statuses = [
    { value: "Entregue", label: "Entregue" },
    { value: "Em Trânsito", label: "Em Trânsito" },
    { value: "Não Entregue", label: "Não Entregue" },
  ];

  return (
    <div className="bg-white shadow-md rounded-lg p-6 mb-6">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4">
        <div>
          <label
            htmlFor="search"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            Pesquisar
          </label>
          <input
            id="search"
            type="text"
            placeholder="Pesquisar..."
            className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-sm"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <DropdownMultiSelect
          options={months}
          value={selectedMonths}
          onChange={setSelectedMonths}
          placeholder="Selecione os meses"
          label="Meses"
        />
        <DropdownMultiSelect
          options={urgencies}
          value={selectedUrgencies}
          onChange={setSelectedUrgencies}
          placeholder="Selecione as urgências"
          label="Urgências"
        />
        <DropdownMultiSelect
          options={statuses}
          value={selectedStatuses}
          onChange={setSelectedStatuses}
          placeholder="Selecione os status"
          label="Status"
        />
        <div className="flex items-center">
          <input
            id="show-delivered"
            type="checkbox"
            className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
            checked={showDelivered}
            onChange={(e) => setShowDelivered(e.target.checked)}
          />
          <label
            htmlFor="show-delivered"
            className="ml-2 block text-sm text-gray-900"
          >
            Exibir entregues
          </label>
        </div>
      </div>
    </div>
  );
};

export default FilterBar;
