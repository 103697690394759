import React from "react";
import DeliveryRow from "./DeliveryRow";

const DeliveryTable = ({ deliveries, getUrgencyLabel, getStatusColor }) => {
  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
        <thead>
          <tr>
            <th className="py-3 px-5 bg-gray-200 text-left text-gray-600 font-bold uppercase text-sm">
              Número do Pedido
            </th>
            <th className="py-3 px-5 bg-gray-200 text-left text-gray-600 font-bold uppercase text-sm">
              Escola
            </th>
            <th className="py-3 px-5 bg-gray-200 text-left text-gray-600 font-bold uppercase text-sm">
              CNPJ
            </th>
            <th className="py-3 px-5 bg-gray-200 text-left text-gray-600 font-bold uppercase text-sm">
              CEP
            </th>
            <th className="py-3 px-5 bg-gray-200 text-left text-gray-600 font-bold uppercase text-sm">
              Data de Entrega
            </th>
            <th className="py-3 px-5 bg-gray-200 text-left text-gray-600 font-bold uppercase text-sm">
              Status
            </th>
            <th className="py-3 px-5 bg-gray-200 text-left text-gray-600 font-bold uppercase text-sm">
              Urgência
            </th>
            <th className="py-3 px-5 bg-gray-200 text-left text-gray-600 font-bold uppercase text-sm">
              Observações
            </th>
          </tr>
        </thead>
        <tbody>
          {deliveries.map((delivery, index) => (
            <DeliveryRow
              key={index}
              delivery={delivery}
              getUrgencyLabel={getUrgencyLabel}
              getStatusColor={getStatusColor}
            />
          ))}
        </tbody>
      </table>
      {deliveries.length === 0 && (
        <div className="text-center py-4 text-gray-500">
          Nenhum resultado encontrado.
        </div>
      )}
    </div>
  );
};

export default DeliveryTable;
