import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Mapa from "./pages/Mapa";
import GerenciarEntregas from "./pages/GerenciarEntregas";
import RoutesPage from "./pages/RoutesPage";
import Navbar from "./components/Navbar";
import PrivateRoute from "./components/PrivateRoute";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/*"
            element={
              <PrivateRoute>
                <Navbar />
                <Routes>
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/mapa" element={<Mapa />} />
                  <Route
                    path="/gerenciar-entregas"
                    element={<GerenciarEntregas />}
                  />
                  <Route path="/routes" element={<RoutesPage />} />
                  <Route path="*" element={<Dashboard />} />
                </Routes>
              </PrivateRoute>
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
