import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import YearFilter from "../components/YearFilter";
import RefreshButton from "../components/RefreshButton";
import UpdateRouteStatusButton from "../components/UpdateRouteStatusButton";
import { checkAuth, authenticatedAxios } from "../utils/auth-utils";

const RoutesPage = () => {
  const [routes, setRoutes] = useState([]);
  const [filteredRoutes, setFilteredRoutes] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const api = useMemo(() => authenticatedAxios(axios, navigate), [navigate]);

  const months = [
    { value: "", label: "Todos os Meses" },
    { value: "01", label: "Janeiro" },
    { value: "02", label: "Fevereiro" },
    { value: "03", label: "Março" },
    { value: "04", label: "Abril" },
    { value: "05", label: "Maio" },
    { value: "06", label: "Junho" },
    { value: "07", label: "Julho" },
    { value: "08", label: "Agosto" },
    { value: "09", label: "Setembro" },
    { value: "10", label: "Outubro" },
    { value: "11", label: "Novembro" },
    { value: "12", label: "Dezembro" },
  ];

  const statuses = ["PENDING", "IN_TRANSIT", "DELIVERED"];

  const getCachedRoutes = useCallback((year, status) => {
    const cacheKey = `routes_${year}_${status || "all"}`;
    const cachedData = localStorage.getItem(cacheKey);
    if (cachedData) {
      const { data, timestamp } = JSON.parse(cachedData);
      const now = new Date().getTime();
      if (now - timestamp < 5 * 60 * 1000) {
        return data;
      }
    }
    return null;
  }, []);

  const setCachedRoutes = useCallback((year, status, data) => {
    const cacheKey = `routes_${year}_${status || "all"}`;
    const cacheData = {
      data,
      timestamp: new Date().getTime(),
    };
    localStorage.setItem(cacheKey, JSON.stringify(cacheData));
  }, []);

  const fetchRoutes = useCallback(
    async (forceRefresh = false) => {
      if (!checkAuth(navigate)) return;

      setIsLoading(true);
      setErrorMessage("");

      if (!forceRefresh) {
        const cachedData = getCachedRoutes(selectedYear, selectedStatus);
        if (cachedData) {
          setRoutes(cachedData);
          setIsLoading(false);
          return;
        }
      }

      try {
        let url = `https://api.logisticaaqq.com.br/routes/filter?year=${selectedYear}`;
        if (selectedStatus) {
          url += `&status=${selectedStatus}`;
        }
        const response = await api.get(url);
        setRoutes(response.data);
        setCachedRoutes(selectedYear, selectedStatus, response.data);
      } catch (error) {
        console.error("Erro ao obter rotas:", error);
        setErrorMessage("Erro ao obter dados. Por favor, tente novamente.");
      } finally {
        setIsLoading(false);
      }
    },
    [
      selectedYear,
      selectedStatus,
      navigate,
      api,
      getCachedRoutes,
      setCachedRoutes,
    ]
  );

  useEffect(() => {
    if (checkAuth(navigate)) {
      fetchRoutes();
    }
  }, [fetchRoutes, navigate]);

  useEffect(() => {
    let filtered = routes;

    if (searchTerm) {
      filtered = filtered.filter((route) =>
        Object.values(route).some(
          (value) =>
            value &&
            value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }

    if (selectedMonth) {
      filtered = filtered.filter((route) => {
        const routeMonth = route.route_date.split("-")[1];
        return routeMonth === selectedMonth;
      });
    }

    if (selectedStatus) {
      filtered = filtered.filter((route) => route.status === selectedStatus);
    }

    setFilteredRoutes(filtered);
  }, [searchTerm, routes, selectedMonth, selectedStatus]);

  const handleStatusUpdate = useCallback(
    (updatedRoute) => {
      setRoutes((prevRoutes) =>
        prevRoutes.map((route) =>
          route.id === updatedRoute.id ? updatedRoute : route
        )
      );
      // Update cache
      const cachedData = getCachedRoutes(selectedYear, selectedStatus);
      if (cachedData) {
        const updatedCachedData = cachedData.map((route) =>
          route.id === updatedRoute.id ? updatedRoute : route
        );
        setCachedRoutes(selectedYear, selectedStatus, updatedCachedData);
      }
    },
    [selectedYear, selectedStatus, getCachedRoutes, setCachedRoutes]
  );

  const handleRouteDelete = useCallback(
    (deletedRouteId) => {
      setRoutes((prevRoutes) =>
        prevRoutes.filter((route) => route.id !== deletedRouteId)
      );
      // Update cache
      const cachedData = getCachedRoutes(selectedYear, selectedStatus);
      if (cachedData) {
        const updatedCachedData = cachedData.filter(
          (route) => route.id !== deletedRouteId
        );
        setCachedRoutes(selectedYear, selectedStatus, updatedCachedData);
      }
    },
    [selectedYear, selectedStatus, getCachedRoutes, setCachedRoutes]
  );

  const onRefresh = useCallback(() => {
    if (checkAuth(navigate)) {
      fetchRoutes(true);
    }
  }, [fetchRoutes, navigate]);

  if (errorMessage) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <div className="p-8 rounded-xl shadow-lg max-w-md w-full bg-white">
          <h2 className="text-2xl font-bold text-center mb-6 text-red-500">
            {errorMessage}
          </h2>
          <button
            onClick={() => navigate("/login")}
            className="w-full py-2 px-4 rounded-lg focus:outline-none focus:ring-2 bg-blue-500 text-white"
          >
            Voltar para Login
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100 p-4">
      <div className="w-full max-w-7xl mx-auto">
        <div className="flex items-center justify-between mb-4">
          <div className="flex space-x-4">
            <YearFilter
              selectedYear={selectedYear}
              setSelectedYear={setSelectedYear}
            />
            <div className="w-48">
              <label className="block mb-2 font-bold">Mês:</label>
              <select
                className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={selectedMonth}
                onChange={(e) => setSelectedMonth(e.target.value)}
              >
                {months.map((month) => (
                  <option key={month.value} value={month.value}>
                    {month.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="w-48">
              <label className="block mb-2 font-bold">Status:</label>
              <select
                className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option value="">Todos os Status</option>
                {statuses.map((status) => (
                  <option key={status} value={status}>
                    {status}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <RefreshButton onRefresh={onRefresh} isLoading={isLoading} />
        </div>

        <div className="mb-4">
          <input
            type="text"
            placeholder="Pesquisar rotas..."
            className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <div className="bg-white shadow-md rounded-lg overflow-hidden">
          <table className="min-w-full">
            <thead>
              <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                <th className="py-3 px-6 text-left">Nome</th>
                <th className="py-3 px-6 text-left">Motorista</th>
                <th className="py-3 px-6 text-left">Data</th>
                <th className="py-3 px-6 text-left">Status</th>
                <th className="py-3 px-6 text-left">Entregas</th>
                <th className="py-3 px-6 text-left">Ações</th>
              </tr>
            </thead>
            <tbody className="text-gray-600 text-sm font-light">
              {filteredRoutes.map((route) => (
                <tr
                  key={route.id}
                  className="border-b border-gray-200 hover:bg-gray-100"
                >
                  <td className="py-3 px-6 text-left whitespace-nowrap">
                    <div className="font-medium">{route.name}</div>
                  </td>
                  <td className="py-3 px-6 text-left">
                    <div>{route.driver_name}</div>
                  </td>
                  <td className="py-3 px-6 text-left">
                    <div>{route.route_date}</div>
                  </td>
                  <td className="py-3 px-6 text-left">
                    <div>{route.status}</div>
                  </td>
                  <td className="py-3 px-6 text-left">
                    <div>{route.deliveries.length} entregas</div>
                  </td>
                  <td className="py-3 px-6 text-left">
                    <UpdateRouteStatusButton
                      route={route}
                      onStatusUpdate={handleStatusUpdate}
                      onRouteDelete={handleRouteDelete}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default RoutesPage;
