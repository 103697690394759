import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { checkAuth } from "../utils/auth-utils";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    // If the user is already authenticated, redirect to the dashboard
    if (checkAuth(navigate)) {
      navigate("/dashboard");
    }
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Clear any previous error messages
    setErrorMessage("");

    // Configuração dos headers para enviar o body como x-www-form-urlencoded
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    // Configuração do body em x-www-form-urlencoded
    const body = new URLSearchParams();
    body.append("username", username);
    body.append("password", password);

    try {
      const response = await fetch(
        "https://api.logisticaaqq.com.br/users/login",
        {
          method: "POST",
          headers: headers,
          body: body,
        }
      );

      let data;

      // Tenta parsear a resposta como JSON
      try {
        data = await response.json();
      } catch (parseError) {
        console.error("Erro ao parsear o JSON da resposta", parseError);
        setErrorMessage("Erro inesperado. Por favor, tente novamente.");
        return;
      }

      if (response.ok) {
        if (data.access_token) {
          // Armazena o token no localStorage
          localStorage.setItem("token", data.access_token);

          // Redireciona para a página de dashboard
          navigate("/dashboard");

          // Limpa qualquer mensagem de erro anterior
          setErrorMessage("");
        } else {
          // Caso o access_token não exista na resposta
          console.error("Token não encontrado na resposta", data);
          setErrorMessage(
            "Erro ao realizar login. Por favor, tente novamente."
          );
        }
      } else {
        // Lida com erros de status HTTP não-ok
        console.error(
          `Erro no login: ${response.status} ${response.statusText}`,
          data
        );

        // Exibe a mensagem de erro vinda do backend, se disponível
        setErrorMessage(
          data.message ||
            "Usuário ou senha inválidos. Por favor, tente novamente."
        );
      }
    } catch (error) {
      // Lida com erros de rede ou outros erros inesperados
      console.error("Erro ao se comunicar com o backend", error);
      setErrorMessage(
        "Erro de rede. Por favor, verifique sua conexão e tente novamente."
      );
    }
  };

  return (
    <div
      className="min-h-screen flex items-center justify-center"
      style={{ backgroundColor: "#7A8B99" }}
    >
      <div
        className="p-8 rounded-xl shadow-lg max-w-md w-full bg-opacity-30"
        style={{ backgroundColor: "#02111B" }}
      >
        <h2
          className="text-2xl font-bold text-center mb-6"
          style={{ color: "#91ADC2" }}
        >
          Login
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="username"
              className="block"
              style={{ color: "#91ADC2" }}
            >
              Usuário
            </label>
            <input
              type="text"
              id="username"
              className="w-full p-2 border border-gray-300 rounded-lg mt-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Insira seu usuário"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              style={{ color: "#91ADC2", borderColor: "#91ADC2" }}
            />
          </div>
          <div className="mb-6">
            <label
              htmlFor="password"
              className="block"
              style={{ color: "#91ADC2" }}
            >
              Senha
            </label>
            <input
              type="password"
              id="password"
              className="w-full p-2 border border-gray-300 rounded-lg mt-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Insira sua senha"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={{ color: "#91ADC2", borderColor: "#91ADC2" }}
            />
          </div>
          {errorMessage && (
            <div className="mb-4 text-red-500">{errorMessage}</div>
          )}
          <button
            type="submit"
            className="w-full py-2 px-4 rounded-lg focus:outline-none focus:ring-2"
            style={{
              backgroundColor: "#FC440F",
              color: "white",
            }}
            onMouseEnter={(e) => (e.target.style.backgroundColor = "#FF6B3B")}
            onMouseLeave={(e) => (e.target.style.backgroundColor = "#FC440F")}
          >
            Entrar
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
