import React from "react";
import { NavLink } from "react-router-dom";

const Navbar = () => {
  return (
    <nav className="bg-[#02111B] p-4">
      <div className="container mx-auto flex items-center justify-between">
        <div className="text-[#91ADC2] font-bold text-xl">
          Logistica Ateliê Quero Quero
        </div>
        <ul className="flex space-x-4">
          <li>
            <NavLink
              to="/dashboard"
              className={({ isActive }) =>
                isActive
                  ? "text-white px-3 py-2 rounded-md text-sm font-medium bg-[#FC440F]"
                  : "text-[#91ADC2] hover:bg-[#FC440F] hover:text-white px-3 py-2 rounded-md text-sm font-medium"
              }
            >
              Dashboard
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/mapa"
              className={({ isActive }) =>
                isActive
                  ? "text-white px-3 py-2 rounded-md text-sm font-medium bg-[#FC440F]"
                  : "text-[#91ADC2] hover:bg-[#FC440F] hover:text-white px-3 py-2 rounded-md text-sm font-medium"
              }
            >
              Mapa
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/gerenciar-entregas"
              className={({ isActive }) =>
                isActive
                  ? "text-white px-3 py-2 rounded-md text-sm font-medium bg-[#FC440F]"
                  : "text-[#91ADC2] hover:bg-[#FC440F] hover:text-white px-3 py-2 rounded-md text-sm font-medium"
              }
            >
              Gerenciar Entregas
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/routes"
              className={({ isActive }) =>
                isActive
                  ? "text-white px-3 py-2 rounded-md text-sm font-medium bg-[#FC440F]"
                  : "text-[#91ADC2] hover:bg-[#FC440F] hover:text-white px-3 py-2 rounded-md text-sm font-medium"
              }
            >
              Routes
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
