// Sidebar.js
import React, { useState } from "react";

const Sidebar = ({
  deliveries,
  selectedDelivery,
  onAddRoute,
  driverName,
  setDriverName,
  routeDate,
  setRouteDate,
  routeNameError,
  driverNameError,
  routeDateError,
  deliveryIdsError,
  errorMessage,
}) => {
  const [selectedDeliveries, setSelectedDeliveries] = useState([]);
  const [routeName, setRouteName] = useState("");

  const toggleDeliverySelection = (delivery) => {
    setSelectedDeliveries((prevSelected) =>
      prevSelected.find((d) => d.id === delivery.id)
        ? prevSelected.filter((d) => d.id !== delivery.id)
        : [...prevSelected, delivery]
    );
  };

  return (
    <div className="w-1/4 bg-white p-4 overflow-y-auto">
      <h2 className="text-xl font-bold mb-4">Add Nova Rota</h2>

      {errorMessage && <div className="mb-4 text-red-500">{errorMessage}</div>}

      <input
        type="text"
        placeholder="Nome da Rota"
        value={routeName}
        onChange={(e) => setRouteName(e.target.value)}
        className="w-full p-2 mb-2 border rounded"
      />
      {routeNameError && (
        <div className="text-red-500 mb-2">{routeNameError}</div>
      )}

      <input
        type="text"
        placeholder="Motorista"
        value={driverName}
        onChange={(e) => setDriverName(e.target.value)}
        className="w-full p-2 mb-2 border rounded"
      />
      {driverNameError && (
        <div className="text-red-500 mb-2">{driverNameError}</div>
      )}

      <input
        type="date"
        value={routeDate}
        onChange={(e) => setRouteDate(e.target.value)}
        className="w-full p-2 mb-2 border rounded"
      />
      {routeDateError && (
        <div className="text-red-500 mb-2">{routeDateError}</div>
      )}

      {deliveryIdsError && (
        <div className="text-red-500 mb-2">{deliveryIdsError}</div>
      )}

      <h3 className="font-bold mt-4 mb-2">Selecione as Escolas:</h3>
      <div className="max-h-60 overflow-y-auto">
        {deliveries.map((delivery) => (
          <div key={delivery.id} className="flex items-center mb-2">
            <input
              type="checkbox"
              id={`delivery-${delivery.id}`}
              checked={selectedDeliveries.some((d) => d.id === delivery.id)}
              onChange={() => toggleDeliverySelection(delivery)}
              className="mr-2"
            />
            <label htmlFor={`delivery-${delivery.id}`}>
              {delivery.npedido} - {delivery.escola}
            </label>
          </div>
        ))}
      </div>

      <button
        onClick={() => onAddRoute(selectedDeliveries, routeName)}
        className="w-full bg-blue-500 text-white p-2 rounded mt-4 hover:bg-blue-600 transition-colors"
      >
        Add Route
      </button>

      {selectedDelivery && (
        <div className="mt-8">
          <h3 className="font-bold mb-2">Entrega Selecionada:</h3>
          <p>
            <strong>Numero do Pedido:</strong> {selectedDelivery.npedido}
          </p>
          <p>
            <strong>Escola:</strong> {selectedDelivery.escola}
          </p>
          <p>
            <strong>CNPJ:</strong> {selectedDelivery.cnpj}
          </p>
          <p>
            <strong>CEP:</strong> {selectedDelivery.cep}
          </p>
          <p>
            <strong>Data Entrega:</strong> {selectedDelivery.data_entrega}
          </p>
          <p>
            <strong>Status:</strong> {selectedDelivery.status}
          </p>
          <p>
            <strong>Região:</strong> {selectedDelivery.region}
          </p>
          <p>
            <strong>OBS:</strong> {selectedDelivery.obs}
          </p>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
