import React, { useState } from "react";
import axios from "axios";
import { authenticatedAxios } from "../utils/auth-utils";
import { useNavigate } from "react-router-dom";

const UpdateRouteStatusButton = ({ route, onStatusUpdate, onRouteDelete }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [newStatus, setNewStatus] = useState(route.status);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const api = authenticatedAxios(axios, navigate);

  const statusOptions = ["PENDING", "IN_TRANSIT", "DELIVERED"];

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => {
    setIsOpen(false);
    setError(null);
  };

  const handleStatusChange = (e) => setNewStatus(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    try {
      const response = await api.patch(
        `https://api.logisticaaqq.com.br/routes/${route.id}`,
        {
          status: newStatus,
        }
      );
      onStatusUpdate(response.data);
      handleClose();
    } catch (err) {
      setError("Falha ao atualizar o status. Por favor, tente novamente.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    if (window.confirm("Tem certeza que deseja excluir esta rota?")) {
      setIsLoading(true);
      setError(null);

      try {
        await api.delete(`https://api.logisticaaqq.com.br/routes/${route.id}`);
        onRouteDelete(route.id);
        handleClose();
      } catch (err) {
        setError("Falha ao excluir a rota. Por favor, tente novamente.");
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <button
        onClick={handleOpen}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        Detalhes / Atualizar
      </button>

      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg p-6 w-full max-w-4xl max-h-[90vh] overflow-y-auto">
            <h2 className="text-2xl font-bold mb-4">{route.name}</h2>
            <div className="grid grid-cols-2 gap-4 mb-4">
              <div>
                <p>
                  <strong>Motorista:</strong> {route.driver_name}
                </p>
                <p>
                  <strong>Data:</strong> {route.route_date}
                </p>
                <p>
                  <strong>Status Atual:</strong> {route.status}
                </p>
              </div>
              <div>
                <form onSubmit={handleSubmit}>
                  <div className="mb-4">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="status"
                    >
                      New Status
                    </label>
                    <select
                      id="status"
                      value={newStatus}
                      onChange={handleStatusChange}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    >
                      {statusOptions.map((status) => (
                        <option key={status} value={status}>
                          {status}
                        </option>
                      ))}
                    </select>
                  </div>
                  {error && (
                    <p className="text-red-500 text-xs italic mb-4">{error}</p>
                  )}
                  <div className="flex items-center justify-between">
                    <button
                      type="submit"
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                      disabled={isLoading}
                    >
                      {isLoading ? "Atualizando..." : "Atualizar Status"}
                    </button>
                    <button
                      type="button"
                      onClick={handleDelete}
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                      disabled={isLoading}
                    >
                      {isLoading ? "Deletando..." : "Deletar a Rota"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <h3 className="text-xl font-semibold mt-4 mb-2">Deliveries</h3>
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="bg-gray-200">
                    <th className="p-2 text-left">Numero do Pedido</th>
                    <th className="p-2 text-left">Escola</th>
                    <th className="p-2 text-left">CNPJ</th>
                    <th className="p-2 text-left">Status</th>
                    <th className="p-2 text-left">Obs</th>
                  </tr>
                </thead>
                <tbody>
                  {route.deliveries.map((delivery) => (
                    <tr key={delivery.id} className="border-b">
                      <td className="p-2">{delivery.npedido}</td>
                      <td className="p-2">{delivery.escola}</td>
                      <td className="p-2">{delivery.cnpj}</td>
                      <td className="p-2">{delivery.status}</td>
                      <td className="p-2">{delivery.obs}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <button
              onClick={handleClose}
              className="mt-4 px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
            >
              Cancelar
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default UpdateRouteStatusButton;
