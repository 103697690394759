import { jwtDecode } from "jwt-decode";

export const checkAuth = (navigate) => {
  const token = localStorage.getItem("token");
  if (!token) {
    navigate("/login");
    return false;
  }

  try {
    const decoded = jwtDecode(token);
    if (!decoded || decoded.exp * 1000 < Date.now()) {
      localStorage.removeItem("token");
      navigate("/login");
      return false;
    }
  } catch (error) {
    console.error("Error decoding token:", error);
    localStorage.removeItem("token");
    navigate("/login");
    return false;
  }

  return true;
};

export const checkTokenExpiration = (navigate) => {
  return checkAuth(navigate);
};

export const authenticatedFetch = async (url, options, navigate) => {
  if (!checkAuth(navigate)) {
    return null;
  }

  const token = localStorage.getItem("token");
  const headers = {
    ...options.headers,
    Authorization: `Bearer ${token}`,
  };

  try {
    const response = await fetch(url, { ...options, headers });
    if (response.status === 401) {
      localStorage.removeItem("token");
      navigate("/login");
      return null;
    }
    return response;
  } catch (error) {
    console.error("Error in authenticatedFetch:", error);
    return null;
  }
};

export const authenticatedAxios = (axios, navigate) => {
  const instance = axios.create();

  instance.interceptors.request.use(
    (config) => {
      if (!checkAuth(navigate)) {
        return Promise.reject("Authentication failed");
      }
      const token = localStorage.getItem("token");
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => Promise.reject(error)
  );

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("token");
        navigate("/login");
      }
      return Promise.reject(error);
    }
  );

  return instance;
};
