import React, { useEffect, useState, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import FilterBar from "../components/FilterBar";
import DeliveryTable from "../components/DeliveryTable";
import YearFilter from "../components/YearFilter";
import RefreshButton from "../components/RefreshButton";
import { checkAuth, authenticatedAxios } from "../utils/auth-utils";

const Dashboard = () => {
  const [deliveries, setDeliveries] = useState([]);
  const [filteredDeliveries, setFilteredDeliveries] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedMonths, setSelectedMonths] = useState([]);
  const [selectedUrgencies, setSelectedUrgencies] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [showDelivered, setShowDelivered] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const api = authenticatedAxios(axios, navigate);

  // Create a ref to store fetchDeliveries
  const fetchDeliveriesRef = useRef();

  const setCache = (year, data) => {
    const cache = JSON.parse(localStorage.getItem("deliveriesCache")) || {};
    cache[year] = { data, timestamp: new Date().getTime() };
    localStorage.setItem("deliveriesCache", JSON.stringify(cache));
  };

  const getCache = (year) => {
    const cache = JSON.parse(localStorage.getItem("deliveriesCache"));
    if (cache && cache[year]) {
      const cachedData = cache[year];
      const cacheExpiry = 1000 * 60 * 10; // 10 minutes cache
      if (new Date().getTime() - cachedData.timestamp < cacheExpiry) {
        return cachedData.data;
      }
    }
    return null;
  };

  const fetchDeliveries = useCallback(
    async (forceRefresh = false) => {
      if (!checkAuth(navigate)) return;

      setIsLoading(true);

      if (!forceRefresh) {
        const cachedData = getCache(selectedYear);
        if (cachedData) {
          setDeliveries(cachedData);
          setFilteredDeliveries(cachedData);
          setIsLoading(false);
          return;
        }
      }

      try {
        const response = await api.get(
          `https://api.logisticaaqq.com.br/deliveries/?year=${selectedYear}`
        );
        setDeliveries(response.data);
        setFilteredDeliveries(response.data);
        setCache(selectedYear, response.data);
      } catch (error) {
        console.error("Erro ao buscar entregas:", error);
        setErrorMessage("Erro ao obter os dados. Por favor, tente novamente.");
      } finally {
        setIsLoading(false);
      }
    },
    [selectedYear, navigate, api]
  );

  // Update the ref whenever fetchDeliveries changes
  useEffect(() => {
    fetchDeliveriesRef.current = fetchDeliveries;
  }, [fetchDeliveries]);

  // Use the ref in the main effect
  useEffect(() => {
    if (!checkAuth(navigate)) return;
    fetchDeliveriesRef.current();
  }, [navigate, selectedYear]);

  const getUrgencyLabel = (deliveryDate, deliveryStatus) => {
    if (deliveryStatus === "Entregue") {
      return "Entregue";
    }

    if (!deliveryDate) {
      return "Data Inválida";
    }

    const currentDate = new Date();
    const deliveryDateObj = new Date(deliveryDate);

    if (isNaN(deliveryDateObj.getTime())) {
      return "Data Inválida";
    }

    currentDate.setHours(0, 0, 0, 0);
    deliveryDateObj.setHours(0, 0, 0, 0);

    const timeDiff = deliveryDateObj.getTime() - currentDate.getTime();
    const dayDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

    if (dayDiff < 0) {
      return "Atrasada";
    } else if (dayDiff <= 5) {
      return "Alta";
    } else if (dayDiff <= 10) {
      return "Média";
    } else if (dayDiff >= 20) {
      return "Baixa";
    } else {
      return "Nenhuma";
    }
  };

  useEffect(() => {
    let filtered = deliveries;

    if (searchTerm) {
      filtered = filtered.filter((delivery) =>
        Object.values(delivery).some(
          (value) =>
            value &&
            value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }

    if (selectedMonths.length > 0) {
      filtered = filtered.filter((delivery) => {
        if (delivery.data_entrega) {
          const deliveryDate = new Date(delivery.data_entrega);
          const month = (deliveryDate.getMonth() + 1)
            .toString()
            .padStart(2, "0");
          return selectedMonths.includes(month);
        }
        return false;
      });
    }

    if (selectedUrgencies.length > 0) {
      filtered = filtered.filter((delivery) => {
        const urgencyLabel = getUrgencyLabel(
          delivery.data_entrega,
          delivery.status
        );
        return selectedUrgencies.includes(urgencyLabel);
      });
    }

    if (selectedStatuses.length > 0) {
      filtered = filtered.filter((delivery) =>
        selectedStatuses.includes(delivery.status)
      );
    }

    if (!showDelivered) {
      filtered = filtered.filter((delivery) => delivery.status !== "Entregue");
    }

    setFilteredDeliveries(filtered);
  }, [
    searchTerm,
    selectedMonths,
    selectedUrgencies,
    selectedStatuses,
    showDelivered,
    deliveries,
  ]);

  const getStatusColor = (status) => {
    switch (status) {
      case "Entregue":
        return "bg-green-500";
      case "Em Trânsito":
        return "bg-yellow-500";
      case "Não Entregue":
        return "bg-red-500";
      default:
        return "bg-gray-500";
    }
  };

  const onRefresh = () => {
    // Clear the cache for the current year
    const cache = JSON.parse(localStorage.getItem("deliveriesCache")) || {};
    delete cache[selectedYear];
    localStorage.setItem("deliveriesCache", JSON.stringify(cache));

    // Fetch data again
    fetchDeliveriesRef.current(true);
  };

  if (errorMessage) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <div className="p-8 rounded-xl shadow-lg max-w-md w-full bg-white">
          <h2 className="text-2xl font-bold text-center mb-6 text-red-500">
            {errorMessage}
          </h2>
          <button
            onClick={() => navigate("/login")}
            className="w-full py-2 px-4 rounded-lg focus:outline-none focus:ring-2 bg-blue-500 text-white"
          >
            Voltar ao Login
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100 p-4">
      <div className="w-full max-w-7xl mx-auto">
        <div className="flex items-center justify-between mb-4">
          <YearFilter
            selectedYear={selectedYear}
            setSelectedYear={setSelectedYear}
          />
          <RefreshButton onRefresh={onRefresh} isLoading={isLoading} />
        </div>

        <FilterBar
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          selectedMonths={selectedMonths}
          setSelectedMonths={setSelectedMonths}
          selectedUrgencies={selectedUrgencies}
          setSelectedUrgencies={setSelectedUrgencies}
          selectedStatuses={selectedStatuses}
          setSelectedStatuses={setSelectedStatuses}
          showDelivered={showDelivered}
          setShowDelivered={setShowDelivered}
        />

        <DeliveryTable
          deliveries={filteredDeliveries}
          getUrgencyLabel={getUrgencyLabel}
          getStatusColor={getStatusColor}
        />
      </div>
    </div>
  );
};

export default Dashboard;
