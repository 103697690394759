import React from "react";
import { Navigate } from "react-router-dom";

// Função para verificar se o token está presente no localStorage
const isAuthenticated = () => {
  const token = localStorage.getItem("token");
  return !!token; // Retorna true se o token existir, false caso contrário
};

const PrivateRoute = ({ children }) => {
  return isAuthenticated() ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
