import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AddDeliveryForm from "../components/AddDeliveryForm";
import EditDeliveryForm from "../components/EditDeliveryForm";
import YearFilter from "../components/YearFilter";
import RefreshButton from "../components/RefreshButton";
import { checkAuth, authenticatedAxios } from "../utils/auth-utils";

const GerenciarEntregas = () => {
  const [deliveries, setDeliveries] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedDelivery, setSelectedDelivery] = useState(null);
  const [showAddForm, setShowAddForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const api = useMemo(() => authenticatedAxios(axios, navigate), [navigate]);

  const fetchDeliveries = useCallback(async () => {
    if (!checkAuth(navigate)) return;
    setIsLoading(true);
    try {
      const response = await api.get(
        `https://api.logisticaaqq.com.br/deliveries/?year=${selectedYear}`
      );
      setDeliveries(response.data);
    } catch (error) {
      console.error("Error fetching deliveries:", error);
    } finally {
      setIsLoading(false);
    }
  }, [api, selectedYear, navigate]);

  useEffect(() => {
    fetchDeliveries();
  }, [fetchDeliveries]);

  const filteredDeliveries = useMemo(() => {
    return deliveries.filter((delivery) => {
      const matchesSearch = Object.values(delivery).some(
        (value) =>
          value &&
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      );
      const deliveryYear = new Date(delivery.data_entrega).getFullYear();
      return matchesSearch && deliveryYear === parseInt(selectedYear);
    });
  }, [deliveries, searchTerm, selectedYear]);

  const handleAddDelivery = async (newDelivery) => {
    if (!checkAuth(navigate)) return;
    try {
      await api.post(
        "https://api.logisticaaqq.com.br/deliveries/",
        newDelivery
      );
      setShowAddForm(false);
      fetchDeliveries();
    } catch (error) {
      console.error("Error adding delivery:", error);
    }
  };

  const handleEditDelivery = async (updatedDelivery) => {
    if (!checkAuth(navigate)) return;
    try {
      await api.put(
        `https://api.logisticaaqq.com.br/deliveries/${updatedDelivery.id}/`,
        updatedDelivery
      );
      setSelectedDelivery(null);
      fetchDeliveries();
    } catch (error) {
      console.error("Error updating delivery:", error);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 p-4">
      <div className="mb-4 flex justify-between items-center">
        <input
          type="text"
          placeholder="Pesquisar..."
          className="w-1/3 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <YearFilter
          selectedYear={selectedYear}
          setSelectedYear={setSelectedYear}
        />
        <RefreshButton onRefresh={fetchDeliveries} isLoading={isLoading} />
        <button
          onClick={() => setShowAddForm(!showAddForm)}
          className="px-4 py-2 bg-blue-500 text-white rounded-lg"
        >
          {showAddForm ? "Fechar Formulário" : "Adicionar Entrega"}
        </button>
      </div>

      {showAddForm && <AddDeliveryForm onSuccess={handleAddDelivery} />}

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
          <thead>
            <tr>
              <th className="py-3 px-5 bg-gray-200 text-left text-gray-600 font-bold uppercase text-sm">
                Número do Pedido
              </th>
              <th className="py-3 px-5 bg-gray-200 text-left text-gray-600 font-bold uppercase text-sm">
                Escola
              </th>
              <th className="py-3 px-5 bg-gray-200 text-left text-gray-600 font-bold uppercase text-sm">
                CNPJ
              </th>
              <th className="py-3 px-5 bg-gray-200 text-left text-gray-600 font-bold uppercase text-sm">
                CEP
              </th>
              <th className="py-3 px-5 bg-gray-200 text-left text-gray-600 font-bold uppercase text-sm">
                Data de Entrega
              </th>
              <th className="py-3 px-5 bg-gray-200 text-left text-gray-600 font-bold uppercase text-sm">
                Status
              </th>
              <th className="py-3 px-5 bg-gray-200 text-left text-gray-600 font-bold uppercase text-sm">
                Observações
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredDeliveries.map((delivery) => (
              <tr
                key={delivery.id}
                className="border-b hover:bg-gray-100 cursor-pointer"
                onClick={() => setSelectedDelivery(delivery)}
              >
                <td className="py-3 px-5">{delivery.npedido}</td>
                <td className="py-3 px-5">{delivery.escola}</td>
                <td className="py-3 px-5">{delivery.cnpj}</td>
                <td className="py-3 px-5">{delivery.cep}</td>
                <td className="py-3 px-5">{delivery.data_entrega}</td>
                <td className="py-3 px-5">{delivery.status}</td>
                <td className="py-3 px-5">{delivery.obs}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {selectedDelivery && (
        <EditDeliveryForm
          delivery={selectedDelivery}
          onClose={() => setSelectedDelivery(null)}
          onSave={handleEditDelivery}
        />
      )}
    </div>
  );
};

export default GerenciarEntregas;
