import React, { useState } from "react";
import axios from "axios";

const AddDeliveryForm = ({ onSuccess }) => {
  const [formData, setFormData] = useState({
    npedido: "",
    escola: "",
    cnpj: "",
    cep: "",
    data_entrega: "",
    obs: "",
    status: "Não Entregue",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  // Helper function to validate CNPJ
  const validateCNPJ = (cnpj) => {
    cnpj = cnpj.replace(/[^\d]+/g, ""); // Remove non-numeric characters

    if (cnpj.length !== 14) return false;

    if (/^(\d)\1+$/.test(cnpj)) return false;

    let length = cnpj.length - 2;
    let numbers = cnpj.substring(0, length);
    let digits = cnpj.substring(length);
    let sum = 0;
    let pos = length - 7;

    for (let i = length; i >= 1; i--) {
      sum += numbers.charAt(length - i) * pos--;
      if (pos < 2) pos = 9;
    }

    let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (result !== parseInt(digits.charAt(0))) return false;

    length += 1;
    numbers = cnpj.substring(0, length);
    sum = 0;
    pos = length - 7;

    for (let i = length; i >= 1; i--) {
      sum += numbers.charAt(length - i) * pos--;
      if (pos < 2) pos = 9;
    }

    result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (result !== parseInt(digits.charAt(1))) return false;

    return true;
  };

  // Helper function to validate CEP
  const validateCEP = (cep) => {
    const cepPattern = /^[0-9]{5}-?[0-9]{3}$/; // Valid format #####-###
    return cepPattern.test(cep);
  };

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");

    // Validate CNPJ
    if (!validateCNPJ(formData.cnpj)) {
      setErrorMessage("CNPJ inválido");
      setSuccessMessage("");
      return;
    }

    // Validate CEP
    if (!validateCEP(formData.cep)) {
      setErrorMessage("CEP inválido");
      setSuccessMessage("");
      return;
    }

    try {
      await axios.post(
        "https://api.logisticaaqq.com.br/deliveries/",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setFormData({
        npedido: "",
        escola: "",
        cnpj: "",
        cep: "",
        data_entrega: "",
        obs: "",
        status: "Não Entregue",
      });
      setErrorMessage("");
      setSuccessMessage("Entrega adicionada com sucesso!");
      if (onSuccess) {
        onSuccess(); // Call the onSuccess callback to refresh deliveries list
      }
    } catch (error) {
      console.error("Erro ao adicionar entrega:", error);
      setErrorMessage("Erro ao adicionar a entrega.");
      setSuccessMessage("");
    }
  };

  return (
    <div className="mb-4 p-4 border border-gray-300 rounded-lg bg-white">
      <h2 className="text-xl font-bold mb-4">Adicionar Nova Entrega</h2>
      {errorMessage && <div className="text-red-500 mb-4">{errorMessage}</div>}
      {successMessage && (
        <div className="text-green-500 mb-4">{successMessage}</div>
      )}
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label htmlFor="npedido">Número do Pedido</label>
            <input
              type="text"
              name="npedido"
              value={formData.npedido}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded-lg"
              required
            />
          </div>
          <div>
            <label htmlFor="escola">Escola</label>
            <input
              type="text"
              name="escola"
              value={formData.escola}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded-lg"
              required
            />
          </div>
          <div>
            <label htmlFor="cnpj">CNPJ</label>
            <input
              type="text"
              name="cnpj"
              value={formData.cnpj}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded-lg"
              required
            />
          </div>
          <div>
            <label htmlFor="cep">CEP</label>
            <input
              type="text"
              name="cep"
              value={formData.cep}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded-lg"
              required
            />
          </div>
          <div>
            <label htmlFor="data_entrega">Data de Entrega</label>
            <input
              type="date"
              name="data_entrega"
              value={formData.data_entrega}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded-lg"
              required
            />
          </div>
          <div>
            <label htmlFor="status">Status</label>
            <select
              name="status"
              value={formData.status}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded-lg"
            >
              <option value="Não Entregue">Não Entregue</option>
              <option value="Em Trânsito">Em Trânsito</option>
              <option value="Entregue">Entregue</option>
            </select>
          </div>
        </div>
        <div className="mt-4">
          <label htmlFor="obs">Observações</label>
          <textarea
            name="obs"
            value={formData.obs}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded-lg"
          />
        </div>
        <div className="mt-4 flex justify-end">
          <button
            type="submit"
            className="px-4 py-2 bg-green-500 text-white rounded-lg"
          >
            Adicionar Entrega
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddDeliveryForm;
